import styled from 'styled-components';
import {getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
`;

export const Title = styled.span`
  font-size: 172px;
  display: inline-block;
  padding: ${getSpacing('md')};
  color: ${getTypographyColor('grey')};
`;


export const Text = styled.p`
  padding: ${getSpacing('xl')} ${getSpacing('md')};
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('lg')};
  text-align: center;
`;
