import React from 'react';

import {Wrapper, Title, Text} from './NotFoundPageContent.styles';
import messages from './messages'
import Button from '../Button';
import {MAIN_URL} from '../../constants';

const NotFoundPageContent = () => (
<Wrapper>
  <Title>404</Title>
  <Text>{messages.text}</Text>
  <Button to={MAIN_URL} text={messages.buttonMsg}/>
</Wrapper>
);

export default NotFoundPageContent;
